import React, { useState } from 'react';
import { graphql, StaticQuery, Link } from "gatsby";

//Elements 
import Para from '../../elements/Paragraph'
import Hyperlink from '../../elements/HyperLink'
import Title from '../../elements/Title'
import ReadMore from '../../elements/ReadMore'
import CaseStudy from '../../elements/CaseStudy'
//Images
import Prod1 from '../../images/Home/prod-1.jpg'
import Prod2 from '../../images/Home/stationery-mockup-scene.jpg'
import styled, { css } from 'styled-components'
import { ContentBlock } from '../../static/css/style'
const CaseStudyWrap = styled.div`
    display: grid;
    grid-template-columns:1fr 1fr 1fr;
    grid-template-rows:auto;
    gap:5vw;
    width: 100%;
    flex-wrap: wrap;
    margin:auto;
    @media screen and (max-width: 991px) {
        grid-template-columns:1fr 1fr;
    }
    @media screen and (max-width: 479px) {
        margin-top: 8%;
        grid-template-columns:1fr;
    }
`;

export default (props) => (
    <StaticQuery
        query={graphql`
      query {
            allWpPost(sort: {order: DESC, fields: date}, filter: {categories: {nodes: {elemMatch: {name: {eq: "Journal"}}}}}) {
                nodes {
                title
                content
                date
                slug
                excerpt
                summary {
                    summary
                  }
                categories {
                    nodes {
                    slug
                    name
                    }
                }
                tags {
                    nodes {
                    slug
                    name
                    }
                }
                featuredImage {
                    node {
                    mediaItemUrl
                    }
                }
                }
            }
          }
      `}

        render={data => (
            <div className="bodyBlack">
                <ContentBlock>
                    <Title size="xl" weight="bold" bottom="3%" color="white">journal...</Title>
                    <CaseStudyWrap>
                        {data.allWpPost.nodes.map(({ node }, index) => (
                            <div className="" key={index}>
                                <Link to={data.allWpPost.nodes[index].categories.nodes[0].name == "Case Study" ? "/project/" + data.allWpPost.nodes[index].slug + "/" : "/journal/" + data.allWpPost.nodes[index].slug + "/"} className="text-deco-none" key="index">
                                    <CaseStudy data={data.allWpPost.nodes[index]} key={index}></CaseStudy>
                                </Link>
                            </div>
                        ))}
                    </CaseStudyWrap>
                </ ContentBlock>
            </div>
        )}
    />
)


