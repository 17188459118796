import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Journals from "../views/Journals/Journals"
const Portfolio = () => (
  <Layout overlay={false} navHeight="auto">
    <SEO title="Our Journal" url="https://www.kwazi.in/journals" />
    <Journals></Journals>
  </Layout>
)

export default Portfolio
